import '../styles/global.css';

import React from 'react';
import Helmet from 'react-helmet';
import Home from './home'
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
config.autoAddCss = false



export default () =>(
  <>
  <Helmet>
        <html lang="en" />
        <meta charset="utf-8" />
        <title>Great Pine Resources</title>
        <meta name="keywords" content="greatpineresources, Great Pine Resources, Insurance, Great Pine, AXA"/>
        <meta name="description" content="Great Pine Resources Insurance Company" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
  </Helmet>
  <Home/>
  </>
)